<template>
  <div class="container p-4">
    <h5 class="text-center">{{ this.label }}</h5>
    <canvas :id="this.id"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "Chart",
  props: {
    type: String,
    labels: Array,
    label: String,
    data: Array,
    id: String,
  },
  mounted() {
    const ctx = document.getElementById(this.id);
    this.myChart = new Chart(ctx, {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: [
          {
            label: this.label,
            data: this.data,
            backgroundColor: "rgba(54,73,93,.5)",
            borderColor: "#36495d",
            borderWidth: 2.2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            ticks: {
              precision: 0,
            },
            beginAtZero: true,
          },
        },
      },
    });
  },
};
</script>
